import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import PageLayout from '../components/PageLayout';
import SEO from '../components/seo';
import '../App.css';
require('typeface-rubik');

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const Point = styled.h2`
  font-weight: 400;
  font-size: 25px;
  margin-top: 0px;
`;

const Container = styled(FlexCol)`
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const highlight = {
    background: '#b4f2e1',
    color: '#000000',
    textDecoration: 'none'
};

const IndexPage = () => {
    const [screenWidth, setScreenWidth] = useState('70%');
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => {
        setWindowWidth(window.innerWidth);
        if (window.innerWidth < 480) {
            setScreenWidth('100%');
        }
    }, []);

    return (
        <PageLayout>
            <SEO
                title={'SEOPipes - Technical SEO Analyst'}
                description={'Leverage GatsbyJs to provide a SEO friendly frontend architecture for companies. Setting up the batteries included infrastructure.'}
                keywords={[
                    'SEO Analyst',
                    'SEO Technical',
                    'SEO Developer',
                    'SEO Analytics'
                ]}
                image={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fseopipes.jpg?alt=media&token=bd419eee-7923-4cb8-aebc-f2bbb150e078'}
            />
            <Container style={{backgroundColor: '#2ecc7112'}}>
                <Banner windowWidth={windowWidth}/>
            </Container>

            <div style={{textAlign: 'center'}}>
                <Container>
                    <picture>
                        <img
                            style={{
                                width: '15%',
                                borderImageWidth: '2px',
                                borderColor: '#00000',
                                borderRadius: '50%'
                            }}
                            alt='SEO Analyst'
                            src={'https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fharsh.png?alt=media&token=0ebf4601-f177-4127-959e-997b8b9531b1'}
                        />
                    </picture>
                </Container>
                <div style={{fontWeight: '600', fontSize: '25px', textAlign: 'center', lineHeight: '1.3'}}>
                    {`Hi, I am Harsh Vardhan. A developer turned SEO specialist`}
                </div>
            </div>

            <br/>

            <div style={{marginTop: 30}}>
                <Container style={{margin: '0 auto', width: screenWidth, backgroundColor: '#2ecc7112'}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        What differentiates your service?
                    </div>
                    <br/>
                    <div style={{fontSize: '25px', fontWeight: '400', marginTop: '20px', lineHeight: '1.3', textAlign: 'center'}}>
                        I leverage high-performance tools like GatsbyJs to provide a fully SEO friendly frontend architecture for companies so that <b style={highlight}>you only need a technical SEO analyst once</b> for setting up the batteries included infrastructure.
                    </div>
                </Container>
            </div>

            <div style={{marginTop: 30}}>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        {`What's involved in your strategy?`}
                    </div>
                    <h2 style={{fontSize: '25px', fontWeight: '400', lineHeight: '1.3', textAlign: 'left'}}>
                        <div className='rcards'>
                            <div className='rcard'>
                                <ul className='a'>
                                    <li><Point>Setting GatsbyJs for landing page and content</Point></li>
                                    <li><Point>Keeping Google PageSpeed insight score near 100</Point></li>
                                    <li><Point>Making Blogs SEO compliant by default </Point></li>
                                </ul>
                            </div>
                            <div className='rcard'>
                                <ul className='a'>
                                    <li><Point>Researching the right keywords for content</Point></li>
                                    <li><Point>Optimising Media Content (Video & Image)</Point></li>
                                    <li><Point>Auditing Pages using Ahrefs site auding tool</Point></li>
                                </ul>
                            </div>
                        </div>
                    </h2>
                </Container>
            </div>

            <div>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <div style={{fontWeight: '600', fontSize: '35px', textAlign: 'center', lineHeight: '1.3'}}>
                        {`Any Proven Work?`}
                    </div>

                    <Container style={{textAlign: 'center'}}>
                        <Carousel
                            autoPlay={true}
                            infiniteLoop={true}
                            style={{width: '70%'}}
                        >
                            <div>
                                <img
                                    alt='shorttail-keyword'
                                    src='https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Fshorttail.png?alt=media&token=ddb04348-504f-4069-9907-6e013311cc02'
                                />
                            </div>
                            <div>
                                <img
                                    alt='longtail-keyword'
                                    src='https://firebasestorage.googleapis.com/v0/b/squarespace-chat.appspot.com/o/images%2Flongtail.png?alt=media&token=0752b697-f3b8-4d20-91ba-e384d5b2b9d0'
                                />
                            </div>
                        </Carousel>
                    </Container>

                    <h3 style={{fontSize: '25px', fontWeight: '400', marginTop: '20px', lineHeight: '1.3', textAlign: 'center'}}>
                        Check out  my product <b>
                            <a
                                href='https://salesjump.xyz/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                SalesJump
                            </a>
                        </b>, which now ranks on the 2nd page after just 2 months of release. The site has been optimized using &nbsp;
                        <b>
                            <a
                                href='https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fsalesjump.xyz%2F'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                {'Google page speed'}
                            </a>
                        </b>
                        . Every strategy that will be applied in my service has been previously battle-tested in my product.
                    </h3>
                </Container>
            </div>

            <div style={{backgroundColor: '#2ecc7112'}}>
                <Container style={{margin: '0 auto', width: screenWidth}}>
                    <button
                        style={{margin: '100px 0 100px 0'}}
                        onClick={() =>
                            window.location.href = 'mailto:harsh.vardhan.611@gmail.com?subject=SEO'
                        }
                    >
                        Email Harsh
                    </button>
                </Container>
            </div>

        </PageLayout>
    );
};

const Banner = ({windowWidth}) => {
    if (windowWidth > 480) {
        return (
            <>
                <h1 style={{textAlign: 'center', fontSize: '5vw', lineHeight: '1.5', fontWeight: '600'}}>
                    Your Technical SEO Consultant <br/> 👇
                </h1>
            </>
        );
    } else {
        return (
            <>
                <h1 style={{fontSize: '9vw', textAlign: 'center', lineHeight: '1.3'}}>
                    Your Technical SEO Consultant <br/> 👇
                </h1>
            </>
        );
    }
};

export default IndexPage;
